// SchemaObject.js
import { formatISO, formatISODuration, intervalToDuration } from 'date-fns';

import {
  getPoster,
  getAssetManifest,
  getAssetTrailer,
  getUserPicture,
  MediaAssetFormat,
  MediaPreset,
} from '../resource/getMediaAsset.js';

import faviconPngSource from '../../img/favicon.png';

/**
 * get coporation
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getCoporation = ({ origin = 'https://swag.live' } = {}) => {
  const logoImageSource = `${origin}/${faviconPngSource.src || faviconPngSource}`;
  return {
    '@type': 'Corporation',
    '@id': `${origin}/#corporation`,
    name: 'SWAG',
    url: `${origin}`,
    logo: {
      '@type': 'ImageObject',
      '@id': `${origin}/#logo`,
      url: logoImageSource,
      contentUrl: logoImageSource,
      width: 512,
      height: 512,
      caption: 'SWAG',
    },
    image: {
      '@id': `${origin}/#logo`,
    },
    sameAs: [
      'https://zh.wikipedia.org/zh-tw/Swag',
      'https://twitter.com/swaglive_zh',
      'https://www.instagram.com/swaglive.zh',
      'https://www.youtube.com/@SWAGLIVE',
      'https://www.youtube.com/@swaglive4483',
      'https://t.me/swag',
      'https://www.facebook.com/sqag.live',
      'https://www.tiktok.com/@swaglive_jp',
      'https://discord.com/invite/BgNhKVwE5d',
    ],
  };
};

/**
 * get interaction counter object
 * @param {string} {type} - type (ex: 'BuyAction', 'LikeAction')
 * @param {number} {count} - action count
 * @return {Object}
 */
export const getInteractionCounter = ({ type, count }) => {
  return {
    '@type': 'InteractionCounter',
    interactionType: type.startsWith('https')
      ? type
      : `https://schema.org/${type}`,
    userInteractionCount: Math.floor(count),
  };
};

/**
 * get person object
 * @param {string} {id} - user id
 * @param {string} {username} - username
 * @param {string} {biography} - biography
 * @param {array} {hasOfferCatalog} - item list of video objects
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getPerson = ({
  id,
  username,
  biography,
  hasOfferCatalog,
  origin = 'https://swag.live',
}) => {
  return {
    '@type': 'Person',
    '@id': `${origin}/user/${id}#person`,
    url: `${origin}/user/${id}`,
    name: username,
    description: biography,
    sameAs: username ? [`${origin}/u/${username}`] : undefined,
    image: {
      '@type': 'ImageObject',
      '@id': `${origin}/user/${id}#avatar`,
      url: getUserPicture({
        userId: id,
        size: 128,
        format: MediaAssetFormat.JPG,
      }).href,
      width: 128,
      height: 128,
    },
    hasOfferCatalog,
  };
};

/**
 * get flix video object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {title} - title
 * @param {string} {username} - author name
 * @param {string} {description} - description
 * @param {number} {durationSecs} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {array} {interactionStatistic}
 * @param {array} {encodings}
 * @param {object} {associatedMedia}
 * @param {bool} {isFree}
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getFlixVideoObject = ({
  id,
  assetId,
  username,
  title,
  description,
  durationSecs,
  author,
  postedAtUnix,
  isFree,
  interactionStatistic,
  encodings,
  associatedMedia,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  const serialCode = title?.replace(/^\[([^[\]]*)\].*/, '$1');
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/flix/${id}#video-hls`,
    url: `${origin}/flix/${id}`,
    name: title || username,
    description,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: MediaPreset.SD,
    })?.href,
    contentUrl: getAssetManifest({
      id,
      assetId,
      format: MediaAssetFormat.M3U8,
      preset: MediaPreset.SD,
    }),
    encodingFormat: 'application/vnd.apple.mpegurl',
    keywords: [serialCode, 'SWAG'].filter(a => a),
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    interactionStatistic,
    encodings,
    associatedMedia,
  };
};

/**
 * get flix preview video object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {username} - author name
 * @param {string} {title} - title
 * @param {string} {description} - description
 * @param {number} {durationSecs = 10} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getFlixPreviewVideoObject = ({
  id,
  assetId,
  username,
  title,
  description,
  durationSecs = 10,
  author,
  postedAtUnix,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/flix/${id}#preview`,
    url: `${origin}/flix/${id}`,
    name: title || username,
    description,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: MediaPreset.SD,
    })?.href,
    contentUrl: getAssetTrailer({
      id,
      assetId,
      format: MediaAssetFormat.MP4,
      preset: MediaPreset.SD,
      duration: durationSecs,
    }),
    encodingFormat: 'video/mp4',
    author,
    uploadDate,
    isAccessibleForFree: true,
    associatedMedia: {
      '@id': `${origin}/flix/${id}#video-hls`,
    },
  };
};

/**
 * get flix hls video object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {title} - title
 * @param {string} {description} - description
 * @param {number} {durationSecs} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {bool} {isFree}
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getFlixHlsVideoObject = ({
  id,
  assetId,
  title,
  description,
  durationSecs,
  author,
  postedAtUnix,
  isFree,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/flix/${id}#video-hls`,
    url: `${origin}/flix/${id}`,
    name: title,
    description,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: MediaPreset.SD,
    })?.href,
    contentUrl: getAssetManifest({
      id,
      assetId,
      format: MediaAssetFormat.M3U8,
      preset: MediaPreset.SD,
    }),
    encodingFormat: 'application/vnd.apple.mpegurl',
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    encodesCreativeWork: {
      '@id': `${origin}/flix/${id}#video-dash`,
    },
  };
};

/**
 * get story video object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {username} - to compose story title
 * @param {string} {caption} - caption
 * @param {number} {durationSecs = 10} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {array} {interactionStatistic}
 * @param {array} [{encodings}]
 * @param {object} [{associatedMedia}]
 * @param {bool} {isFree}
 * @param {array} {categories} - message categories
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getStoryVideoObject = ({
  id,
  assetId,
  username,
  titleTail,
  caption,
  durationSecs = 10,
  author,
  postedAtUnix,
  isFree,
  interactionStatistic,
  encodings,
  associatedMedia,
  categories = [],
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  const name = titleTail ? `${username} ${titleTail}` : username;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/story/${id}#video-hls`,
    url: `${origin}/story/${id}`,
    name,
    description: caption || name,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: MediaPreset.SD,
    })?.href,
    contentUrl: getAssetManifest({
      id,
      assetId,
      format: MediaAssetFormat.M3U8,
      preset: MediaPreset.SD,
    }),
    encodingFormat: 'application/vnd.apple.mpegurl',
    keywords: [username, ...categories, 'SWAG'],
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    interactionStatistic,
    encodings,
    associatedMedia,
  };
};

/**
 * get story preview video object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {username} - to compose story title
 * @param {string} {caption} - caption
 * @param {number} {durationSecs = 10} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getStoryPreviewVideoObject = ({
  id,
  assetId,
  username,
  titleTail,
  caption,
  durationSecs = 10,
  author,
  postedAtUnix,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  const name = titleTail ? `${username} ${titleTail}` : username;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/story/${id}#preview`,
    url: `${origin}/story/${id}`,
    name,
    description: caption || name,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: MediaPreset.SD,
    })?.href,
    contentUrl: getAssetManifest({
      id,
      assetId,
      format: MediaAssetFormat.M3U8,
      preset: MediaPreset.SD_PREVIEW,
    }),
    encodingFormat: 'application/vnd.apple.mpegurl',
    author,
    uploadDate,
    isAccessibleForFree: true,
    associatedMedia: {
      '@id': `${origin}/story/${id}#video-hls`,
    },
  };
};

/**
 * get post video object
 * @param {string} {id} - message id
 * @param {string} {title} - title
 * @param {string} {description} - description
 * @param {number} {durationSecs} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {array} {interactionStatistic}
 * @param {array} {encodings}
 * @param {object} {associatedMedia}
 * @param {bool} {isFree}
 * @param {array} {categories} - message categories
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getPostVideoObject = ({
  id,
  title,
  description,
  durationSecs,
  author,
  username,
  postedAtUnix,
  isFree,
  interactionStatistic,
  encodings,
  associatedMedia,
  thumbnailUrl,
  contentUrl,
  hashtags = [],
  categories = [],
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  const serialCode = title?.replace(/^\[([^[\]]*)\].*/, '$1');
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/post/${id}#video-hls`,
    url: `${origin}/post/${id}`,
    name: title,
    description,
    duration,
    thumbnailUrl,
    contentUrl,
    encodingFormat: 'application/vnd.apple.mpegurl',
    keywords: [serialCode, username, ...hashtags, ...categories, 'SWAG'].filter(
      a => a
    ),
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    interactionStatistic,
    encodings,
    associatedMedia,
  };
};

/**
 * get post preview video object
 * @param {string} {id} - message id
 * @param {string} {title} - title
 * @param {string} {description} - description
 * @param {number} {durationSecs = 10} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getPostPreviewVideoObject = ({
  id,
  title,
  description,
  durationSecs = 10,
  author,
  postedAtUnix,
  thumbnailUrl,
  contentUrl,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/post/${id}#preview`,
    url: `${origin}/post/${id}`,
    name: title,
    description,
    duration,
    thumbnailUrl,
    contentUrl,
    encodingFormat: 'application/vnd.apple.mpegurl',
    author,
    uploadDate,
    isAccessibleForFree: true,
    associatedMedia: {
      '@id': `${origin}/post/${id}#video-hls`,
    },
  };
};

/**
 * get short schema object
 * @param {string} {id} - message id
 * @param {string} {title} - title
 * @param {string} {description} - description
 * @param {number} {durationSecs} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {array} {interactionStatistic}
 * @param {array} {encodings}
 * @param {object} {associatedMedia}
 * @param {bool} {isFree}
 * @param {array} {categories} - message categories
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getShortSchemaObject = ({
  id,
  title,
  description,
  durationSecs,
  author,
  username,
  postedAtUnix,
  isFree,
  interactionStatistic,
  encodings,
  associatedMedia,
  thumbnailUrl,
  contentUrl,
  hashtags = [],
  categories = [],
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/shorts/${id}#video-hls`,
    url: `${origin}/shorts/${id}`,
    name: title || username,
    description,
    duration,
    thumbnailUrl,
    contentUrl,
    encodingFormat: 'application/vnd.apple.mpegurl',
    keywords: [username, ...hashtags, ...categories, 'SWAG'].filter(a => a),
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    interactionStatistic,
    encodings,
    associatedMedia,
  };
};

/**
 * get post image object
 * @param {string} {id} - message id
 * @param {string} {assetId} - asset id
 * @param {string} {description} - description
 * @param {number} {durationSecs} - seconds
 * @param {object} {author} - author (Person schema object)
 * @param {number} {postedAtUnix} - uploadDate
 * @param {array} {interactionStatistic}
 * @param {array} {encodings}
 * @param {object} {associatedMedia}
 * @param {bool} {isFree}
 * @param {string} {origin} - URL origin
 * @return {Object}
 */
export const getPostImageObject = ({
  id,
  assetId,
  description,
  durationSecs,
  author,
  postedAtUnix,
  isFree,
  interactionStatistic,
  encodings,
  associatedMedia,
  origin = 'https://swag.live',
}) => {
  const duration = durationSecs
    ? formatISODuration(
        intervalToDuration({ start: 0, end: durationSecs * 1000 })
      )
    : undefined;
  const uploadDate = postedAtUnix ? formatISO(postedAtUnix * 1000) : undefined;
  return {
    '@type': 'VideoObject',
    '@id': `${origin}/post/${id}#video-hls`,
    url: `${origin}/post/${id}`,
    description,
    duration,
    thumbnailUrl: getPoster({
      id,
      size: '1024x1024',
      format: MediaAssetFormat.JPG,
      preset: isFree ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
    })?.href,
    contentUrl: getAssetManifest({
      id,
      assetId,
      format: MediaAssetFormat.M3U8,
      preset: isFree ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
    }),
    encodingFormat: 'application/vnd.apple.mpegurl',
    keywords: ['SWAG'].filter(a => a),
    author,
    uploadDate,
    isAccessibleForFree: isFree,
    interactionStatistic,
    encodings,
    associatedMedia,
  };
};

/**
 * Get product schema object.
 * @param {string} {id} - product id.
 * @param {string} {type} - product type.
 * @param {string} {title} - product name.
 * @param {string} {titleTail} - title tail wording.
 * @param {string} {description} - product description.
 * @param {string} {nbf} - product nbf.
 * @param {array} {interactionStatistic} - interaction statistic.
 * @param {array} {offers} - offers.
 * @param {string} {origin} - URL origin
 * @return {Object} Return SchemaObject
 */
export const getProductObject = ({
  id,
  type,
  title,
  titleTail,
  description,
  nbf,
  interactionStatistic,
  offers,
  origin = 'https://swag.live',
}) => {
  const logoImageSource = `${origin}/${faviconPngSource.src || faviconPngSource}`;
  const name = titleTail ? `${title} ${titleTail}` : title;
  return {
    '@type': 'Product',
    '@id': `${origin}/product/${id}`,
    brand: 'SWAG',
    category: type,
    keywords: [name, type, 'SWAG'].filter(i => i),
    logo: {
      '@type': 'ImageObject',
      '@id': `${origin}/#logo`,
      url: logoImageSource,
      contentUrl: logoImageSource,
      width: 512,
      height: 512,
      caption: 'SWAG',
    },
    releaseDate: nbf,
    name,
    description,
    interactionStatistic,
    offers,
  };
};
